var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('SliderWrapper',{attrs:{"slides":_vm.items,"has-preview":false,"is-multi-image":false,"start-from":_vm.startFrom},scopedSlots:_vm._u([{key:"default",fn:function({
        slides,
        prev,
        next,
        currentIndex,
        getClass
    }){return [_c('div',{staticClass:"base-slider"},[_c('div',{staticClass:"base-slider__slider"},[_c('div',{staticClass:"base-slider__img-wrap"},_vm._l((slides),function(slide,index){return _c('div',{directives:[{name:"swipe",rawName:"v-swipe:left",value:(next),expression:"next",arg:"left"},{name:"swipe",rawName:"v-swipe:right",value:(prev),expression:"prev",arg:"right"}],key:`slide-${index}`,staticClass:"base-slider__slide",class:getClass(index)},[_c('image-component',{staticClass:"base-slider__img",attrs:{"controls":true,"img":slide.img || slide}})],1)}),0),_vm._v(" "),_c('SliderNavBtns',{staticClass:"d-none d-lg-block",on:{"prev":prev,"next":next}})],1),_vm._v(" "),_c('div',{staticClass:"base-slider__slide-content"},[(slides[currentIndex] && slides[currentIndex].text)?_c('div',{staticClass:"base-slider__text"},[_vm._v("\n                "+_vm._s(_vm.striptags(slides[currentIndex].text))+"\n            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"base-slider__slide-count d-none d-lg-block"},[_vm._v("\n                "+_vm._s(currentIndex + 1)+" / "+_vm._s(slides.length)+"\n            ")])]),_vm._v(" "),_c('SliderBullets',{staticClass:"base-slider__bullets d-lg-none",attrs:{"slides-amount":slides.length,"current":currentIndex}})],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }